export {}

import './static';

/**
 * String拡張
 */
declare global {
  interface String {

    // 数値文字列化
    toNumber(): `${number}` | null;

    // 数値文字列化
    toNumber(precision: 0): `${number}` | null;

    // 数値文字列化
    toNumber(precision: number): `${number}` | `${number}.${number}` | null;

    // 日付化
    toDate(): Date;

    // 数字にカンマを付与
    get withComma(): string;

    // カナ -> ｶﾅ
    get kanaHalf(): string;
  }
}


String.prototype.toNumber = function (precision: number = 0): any {
  if (precision < 0) {
    throw new Error('精度には0以上の値の指定が必要です');
  }

  const tmp = this.replace(/[０-９．]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xFEE0))
                  .replaceAll(/([^\-0-9.])/g, '');

  let [int, dec] = tmp.split('.');
  dec            = (dec || '') + "0".repeat(precision);


  const ret: `${number}` | `${number}.${number}` = (precision === 0) ? `${Number(int)}`
                                                                     : `${Number(int)}.${(dec ?? "0").substring(0, precision)}` as `${number}.${number}`;

  return isNaN(Number(ret)) ? null : ret;
}

String.prototype.toDate = function () {
  const [ymd, time] = this.trim().split(' ');
  return new Date(`${ymd} ${time?.split(':').concat(['0', '0', '0']).splice(0, 4).join(':') ?? '0:0:0:0'}`);
}

const KanaFullToHalf = {
  'ァ': 'ｱ', 'ィ': 'ｲ', 'ゥ': 'ｳ', 'ェ': 'ｴ', 'ォ': 'ｵ',
  'ア': 'ｱ', 'イ': 'ｲ', 'ウ': 'ｳ', 'エ': 'ｴ', 'オ': 'ｵ',
  'カ': 'ｶ', 'キ': 'ｷ', 'ク': 'ｸ', 'ケ': 'ｹ', 'コ': 'ｺ',
  'ガ': 'ｶﾞ', 'ギ': 'ｷﾞ', 'グ': 'ｸﾞ', 'ゲ': 'ｹﾞ',
  'ゴ': 'ｺﾞ', 'サ': 'ｻ', 'シ': 'ｼ', 'ス': 'ｽ', 'セ': 'ｾ', 'ソ': 'ｿ',
  'ザ': 'ｻﾞ', 'ジ': 'ｼﾞ', 'ズ': 'ｽﾞ', 'ゼ': 'ｾﾞ', 'ゾ': 'ｿﾞ',
  'ッ': 'ﾂ',
  'タ': 'ﾀ', 'チ': 'ﾁ', 'ツ': 'ﾂ', 'テ': 'ﾃ', 'ト': 'ﾄ',
  'ダ': 'ﾀﾞ', 'ヂ': 'ﾁﾞ', 'ヅ': 'ﾂﾞ', 'デ': 'ﾃﾞ', 'ド': 'ﾄﾞ',
  'ナ': 'ﾅ', 'ニ': 'ﾆ', 'ヌ': 'ﾇ', 'ネ': 'ﾈ', 'ノ': 'ﾉ',
  'ハ': 'ﾊ', 'ヒ': 'ﾋ', 'フ': 'ﾌ', 'ヘ': 'ﾍ', 'ホ': 'ﾎ',
  'バ': 'ﾊﾞ', 'ビ': 'ﾋﾞ', 'ブ': 'ﾌﾞ', 'ベ': 'ﾍﾞ', 'ボ': 'ﾎﾞ',
  'パ': 'ﾊﾟ', 'ピ': 'ﾋﾟ', 'プ': 'ﾌﾟ', 'ペ': 'ﾍﾟ', 'ポ': 'ﾎﾟ',
  'マ': 'ﾏ', 'ミ': 'ﾐ', 'ム': 'ﾑ', 'メ': 'ﾒ', 'モ': 'ﾓ',
  'ャ': 'ﾔ', 'ュ': 'ﾕ', 'ョ': 'ﾖ',
  'ヤ': 'ﾔ', 'ユ': 'ﾕ', 'ヨ': 'ﾖ',
  'ラ': 'ﾗ', 'リ': 'ﾘ', 'ル': 'ﾙ', 'レ': 'ﾚ', 'ロ': 'ﾛ',
  'ワ': 'ﾜ', 'ヲ': 'ｦ', 'ン': 'ﾝ',
  'ヴ': 'ｳﾞ',
  'ヷ': 'ﾜﾞ', 'ヺ': 'ｦﾞ',
  '。' : '｡', '「': '｢', '」': '｣', '、': '､', '・': '･', 'ー': '-', '－': '-', '，': ',', '．': '.',
  '）' : ')', '（': '(', '　': ' '
} as const;


Object.defineProperty(String.prototype, 'withComma', {
  get: function () {
    const orig = this.trim();
    if (orig === '') {
      return '';
    }

    let [int, dec] = orig.split('.');
    int            = int.replace(/(\d)(?=(\d\d\d)+$)/g, '$1,');

    if (dec) {
      return `${int}.${dec}`;
    }
    return `${int}`;
  }
});

Object.defineProperty(String.prototype, 'kanaHalf', {
  get: function () {
    const regexp = new RegExp('(' + Object.keys(KanaFullToHalf).join('|') + ')', 'g');

    const orig: string = this;
    return orig.replace(regexp, c => KanaFullToHalf[c as keyof typeof KanaFullToHalf]);
  }
})
